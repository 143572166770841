import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import CtaPrimary from '../Buttons/primary-cta';
import CtaSecondary from '../Buttons/secondary-cta';
import NoVisualsStyles from './NoVisualsStyles';

const { Title } = Typography;

const NoVisuals = (props) => {
  const { sectionData } = props;
  const { title, subtext, ctaPrimary, ctaSecondary } = sectionData;
  return (
    <section className="no-visuals">
      <Title level={2} className="text-center">
        {title}
        <span className="blue_dot">.</span>{' '}
      </Title>
      <p className="text-center">{subtext}</p>
      {ctaPrimary && (
        <CtaPrimary target={ctaPrimary.target} ctaTitle={ctaPrimary.title} />
      )}
      {ctaSecondary && (
        <CtaSecondary
          target={ctaSecondary.target}
          ctaTitle={ctaSecondary.title}
        />
      )}

      <NoVisualsStyles />
    </section>
  );
};

NoVisuals.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
NoVisuals.defaultProps = {
  sectionData: '',
};

export default NoVisuals;
